import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import vuetify from './plugins/vuetify'
import './assets/tailwind.css'
import store from './store'
import api from './api'
import VueApexCharts from 'vue-apexcharts'
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";

Maps(Highcharts);
Vue.use(HighchartsVue);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.prototype.$http = api;
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
