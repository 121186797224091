import axios from 'axios'
import store from './store/index'
const version="v2.1";
store.commit('setVersion',version);
export default axios.create({
 baseURL: `https://sharethis-backend-test-pipe-01.azurewebsites.net/api/${version}/`,
 //baseURL: `https://sharethisdemo-backend.azurewebsites.net/api/${version}/`,
 //baseURL: `http://127.0.0.1:8000/api/${version}/`,
 headers: {
 'Accept': 'application/json',
 'Content-Type': 'application/json'
 }
})