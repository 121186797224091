import Vue from 'vue'
import VueRouter from 'vue-router' 
// import store from '../store'

Vue.use(VueRouter)
// var stepper=store.getters.getStepper
const routes = [
  {
    path: "/",
    component: () =>
    import(/* webpackChunkName: "Sharethis lyout" */ "../layout/SharethisLayout.vue"),
    children: [
      {
        path: "/industry-category",
        name: "industry-category",
        component: () =>
          import(/* webpackChunkName: "Industry-Category" */ "../components/IndustryCategory.vue"),
      },
      {
        path: "/customers-events",
        name: "customers-events",
        component: () =>
          import(/* webpackChunkName: "customers-events" */ "../components/CustomersEvents.vue"),
      },
      {
        path: "/categories-insights",
        name: "categories-insights",
        component: () =>
          import(/* webpackChunkName: "categories-insights" */ "../components/CategoriesInsights.vue"),
      },
      {
        path: "/categories-insights-us",
        name: "categories-insights-us",
        component: () =>
          import(/* webpackChunkName: "categories-insights-us" */ "../components/CategoriesInsightsUs.vue"),
      },
      {
        path: "/activation",
        name: "activation",
        component: () =>
          import(/* webpackChunkName: "activation" */ "../components/Activation.vue"),
      },
      {
        path: "/target-categories",
        name: "target-categories",
        component: () =>
          import(/* webpackChunkName: "target-categories" */ "../components/TargetCategories.vue"),
      },
      {
        path: "/",
        name: "crm-data-overview",
        component: () =>
          import(/* webpackChunkName: "crm-data-overview" */ "../components/CrmDataOverview.vue"),
      },
      {
        path: "/crm-filtering",
        name: "crm-filtering",
        component: () =>
          import(/* webpackChunkName: "Industry Category" */ "../components/CrmFiltering.vue"),
      }
      ,{
        path: "/tier-processing",
        name: "tier-processing",
        component: () =>
          import(/* webpackChunkName: "tier processing" */ "../components/SelectedTierProcessing.vue"),
      }
      ,{
        path: "/event-insights",
        name: "event-insights",
        component: () =>
          import(/* webpackChunkName: "event insights" */ "../components/EventInsights.vue"),
      }
      ,{
        path: "/data-overview",
        name: "data-overview",
        component: () =>
          import(/* webpackChunkName: "data overview" */ "../components/DataOverview.vue"),
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})

// router.beforeEach((to, from, next) => {
//   console.log(from.path);
//   console.log("from.path ",stepper);
//   if (to.path=='/' && stepper==2 ) {
//     next();
//   }
//   else if (to.path=='/' && stepper==1 ) {
//     next();
//   }
//   else {
//     store.commit('setStepper',1)
//     console.log("setStepper ",stepper);
//     next("/");
//   }
 
// })

export default router
