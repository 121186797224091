import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
var dt = new Date();
var month = dt.getMonth();
var year = dt.getFullYear();
var daysInMonth = new Date(year, month, 0).getDate();
var labels = [];
for (let index = 1; index <= daysInMonth; index++) {
  labels.push(index);
}
export default new Vuex.Store({
  state: {
    setSelectCat:'',
    params: {
      tier_column: "tier",
      category_column: "CRMCategories"
    },
    selectedCat:"",
    activatedIn:{ title: "Activated in ShareThis", approach:"sharethis" },
    city:"CA",
    categoryEvent:"",
    map:"world",
    version:'v2.0-all',
    segBtn:'high',
    selectCategories: [],
    selectSubCategory: "",
    daysOfMonth: labels,
    stepper: { 'sharethis': 1, 'crm': 1 },
    approchName:"kmeans"
  },
  mutations: {
    setMap(state, payload){
      state.map = payload
    },
    setSelectCat(state, payload){
      state.selectCat = payload
    },
    setParams(state, payload){
      state.params = payload
    },
    setActivatedIn(state, payload){
      state.activatedIn = payload
    },
    setCity(state, payload){
      state.city = payload
    },
    setCategoryEvent(state, payload){
      state.categoryEvent = payload
    },
    setSelectedCat(state, payload){
      state.selectedCat = payload
    },
    setVersion(state, payload){
      state.version = payload
    },
    setApproch(state, payload){
      state.approchName = payload
    },
    setSegBtn(state, payload){
      state.segBtn = payload
    },
    setSelectCategories(state, payload) {
      state.selectCategories = payload
    },
    setSelectSubCategory(state, payload) {
      state.selectSubCategory = payload
    },
    setDaysOfMonth(state, payload) {
      state.daysOfMonth = payload
    },
    setStepper(state, payload) {
      if (payload.type == "crm") {
        state.stepper.crm = payload.val
      }
      else
        state.stepper.sharethis = payload.val
    }
  },
  getters: {
    getSelectCat(state) {
      return state.selectCat
    },
    getParams(state) {
      return state.params
    },
    getVersion(state) {
      return state.version
    },
    getActivatedIn(state) {
      return state.activatedIn
    },
    getCity(state) {
      return state.city
    },
    getMap(state) {
      return state.map
    },
    getCategoryEvent(state) {
      return state.categoryEvent
    },
    getSelectedCat(state) {
      return state.selectedCat
    },
    getSegBtn(state) {
      return state.segBtn
    },
    getSelectCategories(state) {
      return state.selectCategories
    },
    getSelectSubCategory(state) {
      return state.selectSubCategory
    },
    getDaysOfMonth(state) {
      return state.daysOfMonth
    },
    getStepper(state) {
      return state.stepper
    },
    getApproch(state) {
      return state.approchName
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
